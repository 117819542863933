import page_json from './config/config.json'

const footerlogo = page_json.background_img.footerlogo;
function Footer() {

    return (
        <div className="container-fluid ftr">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-12 col-">
                        <div className="media">
                            <a href="https://www.linkedin.com/company/engineersmind/" title="LinkedIn" target="blank"><i className="fab fa-linkedin-in"></i></a>
                            {/* <a href="" title="Twitter" target="blank"><i className="fab fa-x-twitter"></i></a>
                            <a href="" title="Youtube" target="blank"><i className="fab fa-youtube"></i></a> */}
                            <div className="ftr-logo">
                                <img src={footerlogo} className="img-fluid" alt="aws" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-">
                        <div className="links">
                            <p>LINKS</p>
                            <ul>
                                <li><a href="/Industries/Healthcare">Industries</a></li>
                                <li><a href="/Services/DigitalTransformation">Services</a></li>
                                <li><a href="/Technologies/Liferay">Technologies</a></li>
                                <li><a href="/Resources">Resources</a></li>
                                <li><a href="/About">About Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-">
                        <div className="links-1">
                            <ul>
                                <li><a href="/Resources/News">News</a></li>
                                <li><a href="/Resources/SuccessStories">Success Stories</a></li>
                                <li><a href="/Resources/Blogs">Blogs</a></li>
                                <li><a href="/About/Careers">Careers</a></li>
                                <li><a href="/Contact">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-">
                        <div className="policies">
                            <p>POLICY</p>
                            <ul>
                                <li><a href="/PrivacyPolicy">PRIVACY & POLICY</a></li>
                                <li><a href="/Termsofuse">TERMS OF USE</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-2 col-sm-4 col-">
                        <div className="address">
                            <p>solutions@engineersmind.com</p>
                            <p>+1 929 298-3609</p>&nbsp;
                            <p>Corporate Headquarters</p>
                            <p>Engineersmind Corp</p>
                            <p>19 Spear Rd STE 310</p>
                            <p>Ramsey, NJ 07446 USA</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ftr-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-">
                            <div className="copy">
                                <p>&copy; 2024 Engineersmind. All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-">
                            <div className="powered">
                                {/* <p>Powered by: lumos.in</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;