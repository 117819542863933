// import for_whom from './content/public/images/For_Whom.webp';
// import objective from './content/public/images/Objective.webp';
// import key_outcome1 from './content/public/images/Key-outcomes-4.webp';
// import page_under_cons from './content/public/images/Page_under_construction.webp';
// import client_logo from './content/public/images/client_Logos.webp';
// import gif from './content/public/gif/Em_Animation-1.gif';
// import key_outcome2 from './content/public/images/Key-outcomes-5.webp';
// import key_outcome3 from './content/public/images/Key-outcomes-6.webp';
// import feature1 from './content/public/images/Features-1.webp';
// import feature2 from './content/public/images/Features-2.webp';
// import stara_adv from './content/public/images/Strategic-Advantages.webp';
// import compailences from './content/public/images/Finra-compliances.webp';
// import build_to_comply from './content/public/images/Build-to-comply.webp';
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

const textDataUrl = page_json.page_jsons.FinancialCs2;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function FinancialCs2() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<div class="container-fluid m-0 p-0 brd-crmb">
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Home</a></li>
									<li class="breadcrumb-item"><a href="/Industries/FinancialServices">Financial Services</a></li>
									<li class="breadcrumb-item active" aria-current="page">Automating Trade Systems</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid casestudy">
				<div class="container">
					<h2><span>{textData.layer1.heading.text1}</span>{textData.layer1.heading.text2}</h2>&nbsp;
					<div class="row">
						<div class="col-lg-4 col-md-4 col-sm-12 col-">
							<div class="casestudy1">
								<img src={textData.layer1.details.data1.img} alt="gif" class="img-fluid" />
								<h4>{textData.layer1.details.data1.heading} </h4>
								<p>{textData.layer1.details.data1.body} </p>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-12 col-">
							<div class="casestudy1">
								<img src={textData.layer1.details.data2.img} alt="gif" class="img-fluid" />
								<h4>{textData.layer1.details.data2.heading} </h4>
								<p>{textData.layer1.details.data2.body} </p>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-12 col-">
							<div class="casestudy1">
								<img src={textData.layer1.details.data3.img} alt="gif" class="img-fluid" />
								<h4>{textData.layer1.details.data3.heading} </h4>
								<p>{textData.layer1.details.data3.body} </p>
							</div>
						</div>
						<h5><span>{textData.layer2.heading}</span></h5>
						<div class="col-lg-3 col-md-3 col-sm-12 col-">
							<div class="casestudy1">
								<img src={textData.layer2.details.data1.img} alt="gif" class="img-fluid" />
								<p>{textData.layer2.details.data1.body}</p>
							</div>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-">
							<div class="casestudy1">
								<img src={textData.layer2.details.data2.img} alt="gif" class="img-fluid" />
								<p>{textData.layer2.details.data2.body}</p>
							</div>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-">
							<div class="casestudy1">
								<img src={textData.layer2.details.data3.img} alt="gif" class="img-fluid" />
								<p>{textData.layer2.details.data3.body}</p>
							</div>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-">
							<div class="casestudy1">
								<img src={textData.layer2.details.data4.img} alt="gif" class="img-fluid" />
								<p>{textData.layer2.details.data4.body}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid Features">
				<div class="container">
					<h2>{textData.layer3.heading}</h2>&nbsp;
					<p>{textData.layer3.body}</p>&nbsp;
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-">&nbsp;
							<img src={textData.layer3.img} alt="gif" class="img-fluid" />
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid cs-solution-3">
				<div class="container">
					<h2><span>{textData.layer4.heading.text1}</span><br />{textData.layer4.heading.text2}</h2>
					<div class="row">
						<div class="custodian-border">
							<div class="col-lg-4 col-md-4 col-sm-12 col-">
								<div class="custodian">
									<p>{textData.layer4.modules.module1}</p>
								</div>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-12 col-">
								<div class="custodian">
									<p>{textData.layer4.modules.module2}</p>
								</div>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-12 col-">
								<div class="custodian">
									<p>{textData.layer4.modules.module3}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="ul-list">
						<h2><span>{textData.layer5.heading.text1}</span><br />{textData.layer5.heading.text2}</h2>
						<div class="row">
							<div class="col-lg-4 col-md-4 col-sm-12 col-">
								<div class="record">
									<p><b>{textData.layer5.details.data1.heading}</b></p>
									<p>{textData.layer5.details.data1.text}<br />{textData.layer5.details.data1.text2}</p>
									<div class="row">
										<div class="col-lg-6 col-md-6 col-sm-12 col-">
											<div class="module-border-wrap">
												<div class="module">
													<p id="pdng">{textData.layer5.details.data1.modules.module1}</p>
												</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-sm-12 col-">
											<div class="module-border-wrap">
												<div class="module">
													<p id="pdng">{textData.layer5.details.data1.modules.module2}</p>
												</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-sm-12 col-">
											<div class="module-border-wrap">
												<div class="module">
													<p id="pdng">{textData.layer5.details.data1.modules.module3}</p>
												</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-sm-12 col-">
											<div class="module-border-wrap">
												<div class="module">
													<p id="pdng">{textData.layer5.details.data1.modules.module4}</p>
												</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-sm-12 col-">
											<div class="module-border-wrap">
												<div class="module">
													<p id="pdng">{textData.layer5.details.data1.modules.module5}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-12 col-">
								<div class="record">
									<p><b>{textData.layer5.details.data2.heading}</b></p>
									<p>{textData.layer5.details.data2.text2}</p>
									<div class="row">
										<div class="col-lg-6 col-md-6 col-sm-12 col-">
											<div class="module-border-wrap">
												<div class="module">
													<p id="pdng">{textData.layer5.details.data2.modules.module1}</p>
												</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-sm-12 col-">
											<div class="module-border-wrap">
												<div class="module">
													<p id="pdng">{textData.layer5.details.data2.modules.module2}</p>
												</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-sm-12 col-">
											<div class="module-border-wrap">
												<div class="module">
													<p id="pdng">{textData.layer5.details.data2.modules.module3}</p>
												</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-sm-12 col-">
											<div class="module-border-wrap">
												<div class="module">
													<p id="pdng">{textData.layer5.details.data2.modules.module4}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-12 col-">
								<div class="record">
									<p><b>{textData.layer5.details.data3.heading}</b></p>
									<p>{textData.layer5.details.data3.text1}<br />{textData.layer5.details.data3.text2}</p>
									<div class="row">
										<div class="col-lg-10 col-md-10 col-sm-10 col-">
											<div class="module-border-wrap">
												<div class="module">
													<p id="pdng">{textData.layer5.details.data3.modules.module1}</p>
												</div>
											</div>
										</div>
										<div class="col-lg-2 col-md-2 col-sm-12 col-"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid Lifecycle">
				<div class="container">
					<h2>{textData.layer6.heading}</h2>&nbsp;
					<div class="row">
						<div class="col-lg-6 col-md-6 col-sm-12 col-">
							<div class="ul-list-1">
								<p>{textData.layer6.details.data1.serial_no}</p>
								<span>{textData.layer6.details.data1.text}</span>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-">
							<div class="ul-list-1">
								<p>{textData.layer6.details.data2.serial_no}</p>
								<span>{textData.layer6.details.data2.text}</span>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-">
							<div class="ul-list-1">
								<p>{textData.layer6.details.data3.serial_no}</p>
								<span>{textData.layer6.details.data3.text}</span>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-">
							<div class="ul-list-1">
								<p>{textData.layer6.details.data4.serial_no}</p>
								<span>{textData.layer6.details.data4.text}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="TA-bg">
				<div className="container">
					<div className="row">
						<h2>{textData.layer7.heading}</h2>
						<div className="col-lg-12 col-md-12 col-sm-12 col-">
							<img src={textData.layer7.img} alt="logos" className="img-fluid" />

						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default FinancialCs2;