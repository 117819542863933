import React, {useEffect, useState } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

const Recaptcha = ({setIsCaptchaValid}) => {
  const [captchaValue, setCaptchaValue] = useState("");

  useEffect(() => {
    loadCaptchaEnginge(6,'white','green'); 
  }, []);

  const checkCaptcha = (value) => {
    if (validateCaptcha(value,false)) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
  };

  return (
    <form style={{
        margin:'25px 0'
    }}>
      <LoadCanvasTemplate />
      <input
        type="text"
        placeholder="Enter CAPTCHA code"
        maxLength={6}
        onChange={(e) => checkCaptcha(e.target.value)}
      />
    </form>
  );
};

export default React.memo(Recaptcha);
