
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

// const banner = 'https://engineersmind-websitecontent.s3.amazonaws.com/About_Banner.webp';
// const image = 'https://engineersmind-websitecontent.s3.amazonaws.com/What-we-do.webp';
// const image1 = 'https://engineersmind-websitecontent.s3.amazonaws.com/Creating-continual-delight.webp';
// const img = 'https://engineersmind-websitecontent.s3.amazonaws.com/Our-solutions.webp';
// const mishra = 'https://engineersmind-websitecontent.s3.amazonaws.com/Deb-Misra.webp';
// const ajay = 'https://engineersmind-websitecontent.s3.amazonaws.com/Ajay-Kaushik.webp';
// const test = 'https://engineersmind-websitecontent.s3.amazonaws.com/Alex-Resnikoff.webp';
// const client_logo = 'https://engineersmind-websitecontent.s3.amazonaws.com/client_Logos.webp';
// const gif = 'https://engineersmind-websitecontent.s3.amazonaws.com/Em_Animation-1.gif';
// const logo = 'https://engineersmind-websitecontent.s3.amazonaws.com/Logos.webp';
// const sateesh = 'https://engineersmind-websitecontent.s3.amazonaws.com/Sateesh N-1.webp';

const textDataUrl = page_json.page_jsons.AboutUs;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function AboutUs() {
    useEffect(() => {
        AOS.init(); // Initialize AOS
    }, []);

    return (
        <div data-aos="fade-up">

            <div className="container-fluid m-0 p-0 brd-crmb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-0">
                <div className="inner-banner">
                    <img src={textData.layer1.img} className="d-block w-100" alt="banner-image" />
                    <div className="inner-banner-text">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                    <p>{textData.layer1.body}</p>
                                    <h2 className="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1}<br /> {textData.layer1.heading.text2} <span>{textData.layer1.heading.text3}<br /> {textData.layer1.heading.text4}</span> {textData.layer1.heading.text5}<br /> <span>{textData.layer1.heading.text6}</span></h2>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-0">
                <img src={textData.layer2.img} className="d-block w-100" alt="banner-image" />
                <div className="container">
                    <div className="wwd">
                        <p>{textData.layer2.body}</p>
                        <h2><span>{textData.layer2.heading.text1}</span> {textData.layer2.heading.text2}</h2>

                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 col-">
                        <div className="ai-text-4">
                            <p><b>{textData.layer3.body}</b></p>
                            <h2>{textData.layer3.heading}</h2>

                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-">
                        <div className="ai-img">
                            <img src={textData.layer3.img} className="img-fluid" alt="industry" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid solution">
                <div className="container">
                    <div className="solutions">
                        <p>{textData.layer4.body1}</p>&nbsp;
                        <p>{textData.layer4.body2}</p>&nbsp;
                        <p>{textData.layer4.body3}</p>&nbsp;
                    </div>
                </div>
            </div>

            <div className="container-fluid leadership">
                <div className="container">
                    <div className="leadership">
                        <h2>{textData.layer5.heading.text1} <span>{textData.layer5.heading.text2}</span></h2>
                    </div>
                    <div class="row">
                        {
                            textData.layer5.editableList_leadership.map(function(item){
                                return(
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-">
                                        <div className="leader-image">
                                            <img src={item.img} className="img-fluid" alt="industry" />
                                            <p>{item.name}</p>
                                            <p><i>{item.post}</i></p>
                                        </div>
                                    </div>)
                              })
                        }
                    </div>
                    <br/>
                    <a href={textData.layer5.clickbutton.url}>{textData.layer5.clickbutton.text} <i className="fa-solid fa-arrow-right"></i></a>
                    {/* <div className="row">

                        <div className="col-lg-3 col-md-3 col-sm-12 col-">
                            <div className="leader-image">
                                <img src={mishra} className="img-fluid" alt="industry" />
                                <p>Deb Misra</p>
                                <p><i>Chief Engineer</i></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-">
                            <div className="leader-image">
                                <img src={ajay} className="img-fluid" alt="industry" />
                                <p>Ajay Kaushik</p>
                                <p><i>Vice President</i></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-">
                            <div className="leader-image">
                                <img src={sateesh} className="img-fluid" alt="industry" />
                                <p>Sateesh Narayan</p>
                                <p><i>Senior Director</i></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-">
                            <div className="leader-image">
                                <img src={test} className="img-fluid" alt="industry" />
                                <p>Alex Resnikoff</p>
                                <p><i>Director of Business Development</i></p>
                            </div>
                        </div>
                        
                    </div> */}
                </div>
            </div>

            <div className="container-fluid trust">
                <div className="container trusted-image">
                    <div className="row">
                    <h2>{textData.layer6.heading}</h2>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-">
                        <img src={textData.layer6.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-">
                            <div className="heading">
                            <h2><span>{textData.layer7.heading.text1}</span> {textData.layer7.heading.text2} <span>{textData.layer7.heading.text3}</span></h2>
                            </div>
                            <div className="more-2">
                            <a href={textData.layer7.button.url}>{textData.layer7.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-">
                            <div className="gif">
                            <img src={textData.layer7.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                        <h2>{textData.layer8.heading}</h2>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-">
                            <img src={textData.layer8.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )

}
export default AboutUs;