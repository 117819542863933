
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

const textDataUrl = page_json.page_jsons.DevOps;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function DevOps() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/Technologies/Salesforce">Technologies </a></li>
									<li className="breadcrumb-item active" aria-current="page">DevOps</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid p-0">
				<div className="inner-banner">
					<img src={textData.layer1.img} className="d-block w-100" alt="banner-image" />
					<div className="inner-banner-text">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
									<h2 className="animate__animated animate__fadeInLeft"><span>{textData.layer1.heading.text1}</span><br />{textData.layer1.heading.text2}</h2>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid manufacture">
				<div className="container">
					<h2>{textData.layer2.heading.text1}<br /> {textData.layer2.heading.text2}</h2>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-">
							<div id="carouselExample" className="carousel slide">
								<div className="carousel-inner">
									{
										textData.layer2.editable_details.map((item, index) => {
											if (index === 0) {
												return (
													<div class={"carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active")}>
														<div className="card-7">
															<p>{item.text}</p>
														</div>
													</div>)
											} else {
												return (
													<div class={"carousel-item carousel-item-".concat(String.toString(index + 1))}>
														<div className="card-7">
															<p>{item.text}</p>
														</div>
													</div>
												)
											}
										})

									}



									{/* <div className="carousel-item carousel-item-1 active">
										<div className="card-7">
											<p>{textData.layer2.carousel.item1.text}</p>
										</div>
									</div>
									<div className="carousel-item carousel-item-2">
										<div className="card-7">
											<p>{textData.layer2.carousel.item2.text}</p>
										</div>
									</div>
									<div className="carousel-item carousel-item-2">
										<div className="card-7">
											<p>{textData.layer2.carousel.item3.text}</p>
										</div>
									</div> */}
								</div>
								<div className="control-btn-9">
									<button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
										<i className="fa-solid fa-arrow-right"></i>
										<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
										<span className="visually-hidden"><p>{textData.layer2.button_next}</p></span>
									</button>
									<button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
										<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
										<i className="fa-solid fa-arrow-left"></i>
										<span className="visually-hidden">{textData.layer2.button_previous}</span>
									</button>
								</div>
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-">
							<div className="manufacture-img">
								<img src={textData.layer2.img} className="img-fluid" alt="industry" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid manufacture">
				<div className="container">
					<h2>{textData.layer3.heading}</h2>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 col-">
							<div className="img">
								<img src={textData.layer3.img} className="img-fluid" alt="industry" />
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-">
							<div id="carouselExampleSlide" className="carousel slide">
								<div className="carousel-inner">
									{
										textData.layer3.editable_details.map((item, index) => {
											if (index === 0) {
												return (
													<div class={"carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active")}>
														<div className="card-8">
															<h5>{item.title} </h5>
															<p>{item.text}</p>
														</div>
													</div>)
											} else {
												return (
													<div class={"carousel-item carousel-item-".concat(String.toString(index + 1))}>
														<div className="card-8">
															<h5>{item.title} </h5>
															<p>{item.text}</p>
														</div>
													</div>
												)
											}
										})

									}


									{/* <div className="carousel-item carousel-item-1 active">
										<div className="card-8">
											<h5>{textData.layer3.carousel.item1.title} </h5>
											<p>{textData.layer3.carousel.item1.text}</p>
										</div>
									</div>
									<div className="carousel-item carousel-item-2">
										<div className="card-8">
											<h5>{textData.layer3.carousel.item2.title} </h5>
											<p>{textData.layer3.carousel.item2.text}</p>
										</div>
									</div>
									<div className="carousel-item carousel-item-2">
										<div className="card-8">
											<h5>{textData.layer3.carousel.item3.title} </h5>
											<p>{textData.layer3.carousel.item3.text}</p>
										</div>
									</div>
									<div className="carousel-item carousel-item-2">
										<div className="card-8">
											<h5>{textData.layer3.carousel.item4.title} </h5>
											<p>{textData.layer3.carousel.item4.text}</p>
										</div>
									</div>
									<div className="carousel-item carousel-item-2">
										<div className="card-8">
											<h5>{textData.layer3.carousel.item5.title} </h5>
											<p>{textData.layer3.carousel.item5.text}</p>
										</div>
									</div>
									<div className="carousel-item carousel-item-2">
										<div className="card-8">
											<h5>{textData.layer3.carousel.item6.title} </h5>
											<p>{textData.layer3.carousel.item6.text}</p>
										</div>
									</div> */}
								</div>
								<div className="control-btn-10">
									<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleSlide" data-bs-slide="next">
										<i className="fa-solid fa-arrow-right"></i>
										<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
										<span className="visually-hidden">{textData.layer3.button_next}</span>
									</button>
									<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleSlide" data-bs-slide="prev">
										<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
										<i className="fa-solid fa-arrow-left"></i>
										<span className="visually-hidden">{textData.layer3.button_previous}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid devops-service">
				<div className="container">
					<h2><span>{textData.layer4.heading1}</span></h2>
					<div className="row">
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p>{textData.layer4.services.service1}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer4.services.service2}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer4.services.service3}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer4.services.service4}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer4.services.service5}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer4.services.service6}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer4.services.service7}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer4.services.service8}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-"></div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer4.services.service9}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer4.services.service10}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-"></div>
					</div>
					<div className="tech-inner">
						<h2><span>{textData.layer4.heading2}</span></h2>
						<div className="row">
							<div className="col-lg-2 col-md-2 col-sm-12 col-">
								<div className="module-border-wrap">
									<div className="module">
										<p id="pdng">{textData.layer4.technologies.tech1}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-2 col-sm-12 col-">
								<div className="module-border-wrap">
									<div className="module">
										<p id="pdng">{textData.layer4.technologies.tech2}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-2 col-sm-12 col-">
								<div className="module-border-wrap">
									<div className="module">
										<p id="pdng">{textData.layer4.technologies.tech3}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-2 col-sm-12 col-">
								<div className="module-border-wrap">
									<div className="module">
										<p id="pdng">{textData.layer4.technologies.tech4}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-2 col-sm-12 col-">
								<div className="module-border-wrap">
									<div className="module">
										<p id="pdng">{textData.layer4.technologies.tech5}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-2 col-sm-12 col-">
								<div className="module-border-wrap">
									<div className="module">
										<p id="pdng">{textData.layer4.technologies.tech6}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid strategic">
				<div className="container">
					<h2>{textData.layer5.heading}</h2>
					<p>{textData.layer5.body}</p>
					<div className="row">
						<ul>
							<li><span>{textData.layer5.advantages.advantage1.serial_no}</span>{textData.layer5.advantages.advantage1.text}</li>
							<li><span>{textData.layer5.advantages.advantage2.serial_no}</span>{textData.layer5.advantages.advantage2.text}</li>
							<li><span>{textData.layer5.advantages.advantage3.serial_no}</span>{textData.layer5.advantages.advantage3.text}</li>
							<li><span>{textData.layer5.advantages.advantage4.serial_no}</span>{textData.layer5.advantages.advantage4.text}</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="container-fluid trust">
				<div className="container trusted-image">
					<div className="row">
						<h2>{textData.layer6.heading}</h2>
						<div className="col-lg-12 col-md-12 col-sm-12 col-">
							<img src={textData.layer6.img} alt="logos" className="img-fluid" />

						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-">
							<div className="heading">
								<h2><span>{textData.layer7.heading.text1}</span>{textData.layer7.heading.text2}<span>{textData.layer7.heading.text3}</span></h2>
							</div>
							<div className="more-2">
								<a href={textData.layer7.button.url}>{textData.layer7.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-">
							<div className="gif">
								<img src={textData.layer7.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h2>{textData.layer8.heading}</h2>
							<div className="col-lg-12 col-md-12 col-sm-12 col-">
								<img src={textData.layer8.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default DevOps;