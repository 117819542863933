
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

// import page_under_cons from './content/public/images/Page_under_construction.webp';
// import client_logo from './content/public/images/client_Logos.webp';
// import gif from './content/public/gif/Em_Animation-1.gif';

// import client from './content/public/images/Client.webp';
// import Challenges from './content/public/images/Challenge.webp';

// import objective from './content/public/images/Objective.webp';
// import key_outcome1 from './content/public/images/Key-outcomes-7.webp';
// import key_outcome2 from './content/public/images/Key-outcomes-8.webp';
// import key_outcome3 from './content/public/images/Key-outcomes-9.webp';
// import key_outcome4 from './content/public/images/Key-outcomes-10.webp';
// import feature1 from './content/public/images/Features-1.webp';
// import feature2 from './content/public/images/Features-2.webp';
// import stara_adv from './content/public/images/Strategic-Advantages.webp';
// import compailences from './content/public/images/Finra-compliances.webp';
// import build_to_comply from './content/public/images/Build-to-comply.webp';
// import custodian from './content/public/images/Custodian-Services.webp'

const textDataUrl = page_json.page_jsons.HealthcareCs1;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function HealthcareCs1() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<div class="container-fluid m-0 p-0 brd-crmb">
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Home</a></li>
									<li class="breadcrumb-item"><a href="/Industries/Healthcare">Healthcare</a></li>
									<li class="breadcrumb-item active" aria-current="page">Integrated User Experiences</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid casestudy">
				<div class="container">
					<h2><span>{textData.layer1.heading1.text1}</span>{textData.layer1.heading1.text2}</h2>
					<div class="row">
						<div class="col-lg-4 col-md-4 col-sm-12 col-">
							<div class="casestudy1">
								<img src={textData.layer1.details.data1.img} alt="gif" class="img-fluid" />
								<h4>{textData.layer1.details.data1.heading}</h4>
								<p>{textData.layer1.details.data1.body}</p>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-12 col-">
							<div class="casestudy1">
								<img src={textData.layer1.details.data2.img} alt="gif" class="img-fluid" />
								<h4>{textData.layer1.details.data2.heading}</h4>
								<p>{textData.layer1.details.data2.body}</p>
							</div>

						</div>
						<div class="col-lg-4 col-md-4 col-sm-12 col-">
							<div class="casestudy1">
								<img src={textData.layer1.details.data3.img} alt="gif" class="img-fluid" />
								<h4>{textData.layer1.details.data3.heading}</h4>
								<p>{textData.layer1.details.data3.body}</p>
							</div>
						</div>
						<h5><span>{textData.layer1.heading2}</span></h5>
						<div class="col-lg-5 col-md-5 col-sm-12 col-">
							<div class="casestudy1">
								<p>{textData.layer1.body}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid framework">
				<div class="container">
					<h2>{textData.layer2.heading}</h2>
					<div class="row">
						<div class="col-lg-4 col-md-4 col-sm-12 col-">
							<div class="frame-image">
								<img src={textData.layer2.details.data1.img} alt="gif" class="img-fluid" />
								<p>{textData.layer2.details.data1.title}</p>
							</div>
							<p>{textData.layer2.details.data1.body}</p>
						</div>
						<div class="col-lg-8 col-md-8 col-sm-12 col-">
							<div class="frame-text">
								<p><span>{textData.layer2.details.data1.points.point1.serial_no}</span> {textData.layer2.details.data1.points.point1.body}</p>
								<p><span>{textData.layer2.details.data1.points.point2.serial_no}</span> {textData.layer2.details.data1.points.point2.body}</p>
								<p><span>{textData.layer2.details.data1.points.point3.serial_no}</span> {textData.layer2.details.data1.points.point3.body}</p>
							</div>
						</div>
						&nbsp;
						<hr />
						<div class="col-lg-4 col-md-4 col-sm-12 col-">
							<div class="frame-image">
								<img src={textData.layer2.details.data2.img} alt="gif" class="img-fluid" />
								<p>{textData.layer2.details.data2.title}</p>
							</div>
							<p>{textData.layer2.details.data2.body}</p>
						</div>
						<div class="col-lg-8 col-md-8 col-sm-12 col-">
							<div class="frame-text">
								<p><span>{textData.layer2.details.data2.points.point1.serial_no}</span> {textData.layer2.details.data2.points.point1.body}</p>
								<p><span>{textData.layer2.details.data2.points.point2.serial_no}</span> {textData.layer2.details.data2.points.point2.body}</p>
							</div>
						</div>
						&nbsp;
						<hr />
						<div class="col-lg-4 col-md-4 col-sm-12 col-">
							<div class="frame-image">
								<img src={textData.layer2.details.data3.img} alt="gif" class="img-fluid" />
								<p>{textData.layer2.details.data3.title}</p>
							</div>
							<p>{textData.layer2.details.data3.body}</p>
						</div>
						<div class="col-lg-8 col-md-8 col-sm-12 col-">
							<div class="frame-text">
								<p><span>{textData.layer2.details.data3.points.point1.serial_no}</span> {textData.layer2.details.data3.points.point1.body}</p>
								<p><span>{textData.layer2.details.data3.points.point2.serial_no}</span> {textData.layer2.details.data3.points.point2.body}</p>
								<p><span>{textData.layer2.details.data3.points.point3.serial_no}</span> {textData.layer2.details.data3.points.point3.body}</p>
								<p><span>{textData.layer2.details.data3.points.point4.serial_no}</span> {textData.layer2.details.data3.points.point4.body}</p>
							</div>
						</div>
						&nbsp;
						<h2>{textData.layer3.heading}</h2>
						<p>{textData.layer3.body.text1}</p>&nbsp;
						<p>{textData.layer3.body.text2}</p>
						<div class="col-lg-2 col-md-2 col-sm-12 col-">
							<div class="module-border-wrap">
								<div class="module">
									<p id="pdng">{textData.layer3.modules.module1}</p>
								</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-2 col-sm-12 col-">
							<div class="module-border-wrap">
								<div class="module">
									<p id="pdng">{textData.layer3.modules.module2}</p>
								</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-2 col-sm-12 col-">
							<div class="module-border-wrap">
								<div class="module">
									<p id="pdng">{textData.layer3.modules.module3}</p>
								</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-2 col-sm-12 col-">
							<div class="module-border-wrap">
								<div class="module">
									<p id="pdng">{textData.layer3.modules.module4}</p>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-">
							<div class="module-border-wrap">
								<div class="module">
									<p id="pdng">{textData.layer3.modules.module5}</p>
								</div>
							</div>
						</div>
						<img src={textData.layer3.img} class="img-fluid" alt="aws" />
					</div>
				</div>
			</div>

			<div class="container-fluid status">
				<div class="container">
					<h2><span>{textData.layer4.heading1.text1}</span>{textData.layer4.heading1.text2}<br />{textData.layer4.heading1.text3}</h2>
					<div class="row">
						<div class="col-lg-4 col-md-4 col-sm-12 col-">
							<div class="frame-image-1">
								<img src={textData.layer4.details.data1.img} alt="gif" class="img-fluid" />
								<p>{textData.layer4.details.data1.body}</p>
							</div>
							<div class="frame-image-1">
								<img src={textData.layer4.details.data2.img} alt="gif" class="img-fluid" />
								<p>{textData.layer4.details.data2.body}</p>
							</div>
							<div class="frame-image-1">
								<img src={textData.layer4.details.data3.img} alt="gif" class="img-fluid" />
								<p>{textData.layer4.details.data3.body}</p>
							</div>
						</div>
						<div class="col-lg-8 col-md-8 col-sm-12 col-">
							<ul class="numbers">
								<li><span>{textData.layer4.info.info1.serial_no}</span>{textData.layer4.info.info1.body}</li>
								<li><span>{textData.layer4.info.info2.serial_no}</span>{textData.layer4.info.info2.body}</li>
								<li><span>{textData.layer4.info.info3.serial_no}</span>{textData.layer4.info.info3.body}</li>
								<li><span>{textData.layer4.info.info4.serial_no}</span>{textData.layer4.info.info4.body}</li>
								<li><span>{textData.layer4.info.info5.serial_no}</span>{textData.layer4.info.info5.body}</li>
							</ul>
						</div>
					</div>&nbsp;
					<div class="status2">
						<h2><span>{textData.layer4.heading2}</span></h2>
						<p>{textData.layer4.body}</p>
					</div>

				</div>
			</div>
			<div class="TA-bg">
				<div class="container">
					<div class="row">
						<h2>{textData.layer5.heading}</h2>
						<div class="col-lg-12 col-md-12 col-sm-12 col-">
							<img src={textData.layer5.img} alt="logos" class="img-fluid" />

						</div>
					</div>
				</div>
			</div>


		</div>
	)
}

export default HealthcareCs1;