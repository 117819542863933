
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

// const em_animation_gif = 'https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/Em_Animation-1.gif';
// const logos = 'https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/Logos.webp';

const textDataUrl = page_json.page_jsons.TermsOfUse;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function TermsOfUse() {
    useEffect(() => {
        AOS.init(); // Initialize AOS
    }, []);

    return (
        <div data-aos="fade-up">
            <div className="container-fluid m-0 p-0 brd-crmb" id="crumb-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/index">Home </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Terms of use</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid SS">
                <div className="container">
                    <h2 data-aos="fade-down" data-aos-duration="1000">{textData.layer1.heading}</h2>
                    <div className="row">
                        <h6>{textData.layer2.heading}</h6>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-">
                            <ol>
                                <li><p>{textData.layer2.data.point2}</p></li>
                                <li><p>{textData.layer2.data.point3}</p></li>
                                <li><p>{textData.layer2.data.point4}</p></li>
                                <li><p>{textData.layer2.data.point5}</p></li>
                                <li><p>{textData.layer2.data.point6}</p></li>
                                <li><p>{textData.layer2.data.point7}</p></li>
                            </ol>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-">
                            <ol>
                                <li><p>{textData.layer2.data.point8}</p></li>
                                <li><p>{textData.layer2.data.point9}</p></li>
                                <li><p>{textData.layer2.data.point10}</p></li>
                                <li><p>{textData.layer2.data.point11}</p></li>
                                <li><p>{textData.layer2.data.point12}</p></li>
                            </ol>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-">
                            <ol>
                                <li><p>{textData.layer2.data.point13}</p></li>
                                <li><p>{textData.layer2.data.point14}</p></li>
                                <li><p>{textData.layer2.data.point15}</p></li>
                                <li><p>{textData.layer2.data.point16}</p></li>
                                <li><p>{textData.layer2.data.point17}</p></li>
                                <li><p>{textData.layer2.data.point18}</p></li>
                            </ol>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-">
                            <ol>
                                <li><p>{textData.layer2.data.point19}</p></li>
                                <li><p>{textData.layer2.data.point20}</p></li>
                                <li><p>{textData.layer2.data.point21}</p></li>
                            </ol>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h6>{textData.layer3.heading}</h6>
                            <p>{textData.layer3.body.text1}</p>
                            <p>{textData.layer3.body.text2}</p>
                            <p>{textData.layer3.body.text3}</p>&nbsp;

                            <h6>{textData.layer4.heading}</h6>
                            <p>{textData.layer4.body.text1}</p>
                            <p>{textData.layer4.body.text2}</p>&nbsp;

                            <h6>{textData.layer5.heading}</h6>
                            <p>{textData.layer5.body.text1}</p>
                            <p>{textData.layer5.body.text2}</p>&nbsp;

                            <h6>{textData.layer6.heading}</h6>
                            <p>{textData.layer6.body.text1}</p>
                            <p>{textData.layer6.body.text2}</p>
                            <ul>
                                <li>{textData.layer6.data.point1}</li>
                                <li>{textData.layer6.data.point2}</li>
                                <li>{textData.layer6.data.point3}</li>
                                <li>{textData.layer6.data.point4}</li>
                                <li>{textData.layer6.data.point5}</li>
                                <li>{textData.layer6.data.point6}</li>
                                <li>{textData.layer6.data.point7}</li>
                                <li>{textData.layer6.data.point8}</li>
                                <li>{textData.layer6.data.point9}</li>
                                <li>{textData.layer6.data.point10}</li>
                                <li>{textData.layer6.data.point11}</li>
                                <li>{textData.layer6.data.point12}</li>
                                <li>{textData.layer6.data.point13}</li>
                                <li>{textData.layer6.data.point14}</li>
                                <li>{textData.layer6.data.point15}</li>
                                <li>{textData.layer6.data.point16}</li>
                                <li>{textData.layer6.data.point17}</li>
                                <li>{textData.layer6.data.point18}</li>
                                <li>{textData.layer6.data.point19}</li>
                                <li>{textData.layer6.data.point20}</li>
                                <li>{textData.layer6.data.point21}</li>
                                <li>{textData.layer6.data.point22}</li>
                            </ul>&nbsp;

                            <h6>{textData.layer7.heading}</h6>
                            <p>{textData.layer7.body.text1}</p>
                            <ul>
                                <li>{textData.layer7.data.point1}</li>
                                <li>{textData.layer7.data.point2}</li>
                                <li>{textData.layer7.data.point3}</li>
                                <li>{textData.layer7.data.point4}</li>
                                <li>{textData.layer7.data.point5}</li>
                                <li>{textData.layer7.data.point6}</li>
                                <li>{textData.layer7.data.point7}</li>
                                <li>{textData.layer7.data.point8}</li>
                                <li>{textData.layer7.data.point9}</li>
                                <li>{textData.layer7.data.point10}</li>
                                <li>{textData.layer7.data.point11}</li>
                                <li>{textData.layer7.data.point12}</li>
                                <li>{textData.layer7.data.point13}</li>
                            </ul>&nbsp;

                            <h6>{textData.layer8.heading}</h6>
                            <p>{textData.layer8.body.text1}</p>
                            <p>{textData.layer8.body.text2}</p>
                            <p>{textData.layer8.body.text3}</p>&nbsp;

                            <h6>{textData.layer9.heading}</h6>
                            <p>{textData.layer9.body.text1}</p>&nbsp;

                            <h6>{textData.layer10.heading}</h6>
                            <p>{textData.layer10.body.text1}</p>&nbsp;

                            <h6>{textData.layer11.heading}</h6>
                            <p>{textData.layer11.body.text1}</p>
                            <p>{textData.layer11.body.text2}</p>&nbsp;

                            <h6>{textData.layer12.heading}</h6>
                            <p>{textData.layer12.body.text1}</p>
                            <p>{textData.layer12.body.text2}</p>&nbsp;

                            <h6>{textData.layer13.heading}</h6>
                            <p>{textData.layer13.body.text1}</p>&nbsp;

                            <h6>{textData.layer14.heading}</h6>
                            <p>{textData.layer14.body.text1}</p>
                            <p>{textData.layer14.body.text2}</p>&nbsp;
                            <p>{textData.layer14.body.text3}</p>
                            <p>{textData.layer14.body.text4}</p>
                            <p>{textData.layer14.body.text5}</p>
                            <p>{textData.layer14.body.text6}</p>&nbsp;

                            <h6>{textData.layer15.heading}</h6>
                            <p>{textData.layer15.body.text1}</p>&nbsp;

                            <h6>{textData.layer16.heading}</h6>
                            <p>{textData.layer16.body.text1}</p>&nbsp;

                            <h6>{textData.layer17.heading}</h6>
                            <p>{textData.layer17.body.text1}</p>&nbsp;

                            <h6>{textData.layer18.heading}</h6>
                            <p>{textData.layer18.body.text1}</p>&nbsp;

                            <h6>{textData.layer19.heading}</h6>
                            <p>{textData.layer19.body.text1}</p>&nbsp;

                            <h6>{textData.layer21.heading}</h6>
                            <p>{textData.layer21.body.text1}</p>&nbsp;

                            <h6>{textData.layer22.heading}</h6>
                            <p>{textData.layer22.body.text1}</p>
                            <p>{textData.layer22.body.text2}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-">
                            <div className="heading">
                                <h2><span>{textData.layer23.heading.text1}</span>{textData.layer23.heading.text2}<span>{textData.layer23.heading.text3}</span></h2>
                            </div>
                            <div className="more-2">
                                <a href={textData.layer23.button.url}>{textData.layer23.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-">
                            <div className="gif">
                                <img src={textData.layer23.button.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                            <h2>{textData.layer24.heading}</h2>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-">
                                <img src={textData.layer24.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default TermsOfUse;