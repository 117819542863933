
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import AWS from 'aws-sdk';
import page_json from './config/config.json'
import ReactModal from 'react-modal';
import { SES } from '@aws-sdk/client-ses';
import ReCaptcha from './ReCaptcha';
// import Base64 from 'crypto-js/enc-base64';

var textDataUrl = page_json.backend_apis.countries;
var textDataResponse = await fetch(textDataUrl);
const countryList = await textDataResponse.json();

textDataUrl = page_json.page_jsons.Careers;
textDataResponse = await fetch(textDataUrl);
var textData = await textDataResponse.json();

function Careers() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);
	const [activePanel, setActivePanel] = useState(null);
	const [modalButton, setModalButton] = useState(false);
	const [isCaptchaValid,setIsCaptchaValid] = useState(false);

	useEffect(()=>{
		handleChange({})
	},[isCaptchaValid])

	const customStyles = {
		content: {
		  background: 'black',
		  color: 'white'
		},
	  };
	const [jobName, setJobName] = useState(null);
	const  [formData, setFormData] =  useState({});
	const handleClose = () => {
		handleChange({ target : {
			name: 'attachment',
			files: [''],
			value: ''
		}})
		setModalButton(false);
		window.location.reload();
    };
    const handleOpen = (event) => {
        setModalButton(true);
		setJobName(event.target.name);
    };
	const togglePanel = (panelId) => {
		setActivePanel(activePanel === panelId ? null : panelId);
	};
	console.log('env : ',process.env.REACT_APP_AWS_S3_QAT_BUCKET)
	const  handleChange = (event) => {
		var value = formData
		if (event?.target?.name === "Firstname"){
			value.firstname = event.target.value
		} else if (event?.target?.name === "attachment"){
			value.attachment = event.target.files[0]
			value.attachmentName = event.target.value.split(/[\\]+/).pop();
			value.attachmentName = value.attachmentName.replaceAll(" ", "_");
		} else if (event?.target?.name === "Lastname"){
			value.lastname = event.target.value
		} else if (event?.target?.name === "Email"){
			value.email = event.target.value
		} else if (event?.target?.name === "Phone"){
			value.phone = event.target.value
		} else if (event?.target?.name === "City"){
			value.city = event.target.value
		} else if (event?.target?.name === "Country"){
			value.country = event.target.value
		} else if (event?.target?.name === "Comments"){
			value.comment = event.target.value
		} else if (event?.target?.name === "check1"){
			value.check1 = event.currentTarget.checked
		} else if (event?.target?.name === "check2"){
			value.check2 = event.currentTarget.checked
		}
		if (value.firstname !== "" &&  value.attachment !== "" &&  value.lastname !== "" &&  value.email !== ""
			&&  value.phone !== "" &&  value.city !== "" &&  value.country !== "" && value.check1 === true && isCaptchaValid) {
				document.getElementById("clickButton") &&( document.getElementById("clickButton").disabled = false);
		} else {
			document.getElementById("clickButton") && (document.getElementById("clickButton").disabled = true);
		}
		setFormData(value);
	};
	const btnclick = (e) => {
		if (formData.firstname !== ""){
			e.preventDefault();
			var body = textData.layer4.applicant_mail.body.body1 + jobName + textData.layer4.applicant_mail.body.body2 + formData.firstname + textData.layer4.applicant_mail.body.body3 + formData.lastname + textData.layer4.applicant_mail.body.body4 + formData.email + textData.layer4.applicant_mail.body.body5 + formData.phone + textData.layer4.applicant_mail.body.body6 + formData.city + textData.layer4.applicant_mail.body.body7 + formData.country + textData.layer4.applicant_mail.body.body8 + formData.comment + textData.layer4.applicant_mail.body.body9;
			const ses = new SES({
				region: process.env.REACT_APP_AWS_REGION,
				credentials: new AWS.Credentials(process.env.REACT_APP_AWS_ACCESS_KEY_ID, process.env.REACT_APP_AWS_SECRET_ACCESS_KEY)
			});
			var getBase64 = file => {
				return new Promise(resolve => {
					let baseURL = "";
					let reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						baseURL = reader.result;
						resolve(baseURL);
					};
				});
			};
			getBase64(formData.attachment)
			.then(result => {
				const attachmentData = result.split(/[,]+/).pop();
				const emailContent = `From: ${page_json.career_api.fromEmail}
To: ${page_json.career_api.toEmail}
Subject: ${textData.layer4.applicant_mail.subject.subject1} ${jobName} ${textData.layer4.applicant_mail.subject.subject2} ${formData.firstname} ${formData.lastname}
MIME-Version: 1.0
Content-Type: multipart/mixed; boundary="NextPart"

--NextPart
Content-Type: text/html

${body}

--NextPart
Content-Type: application/octet-stream
Content-Transfer-Encoding: base64
Content-Disposition: attachment; filename=${formData.attachmentName}

${attachmentData}
--NextPart--`;
				var params = {
					Destinations: [page_json.career_api.toEmail],
					RawMessage: {
						Data: emailContent
					},
					Source: page_json.career_api.fromEmail,
				};
				
				ses.sendRawEmail(params).then((response) => {
					params = {
						Source: page_json.career_api.fromEmail,
						Destination: {
						  ToAddresses: [formData.toEmail],
						},
						Message: {
						  Body: {
							Html: {
							  Charset: 'UTF-8',
							  Data: textData.layer4.applicant_acknowledgement.body
							},
						  },
						  Subject: {
							Charset: 'UTF-8',
							Data: textData.layer4.applicant_acknowledgement.subject
						  },
						},
					  };
					ses.sendEmail(params)
					.then((response) => {
						alert(textData.layer4.mail_alert.success);
						window.location.reload();
					}).catch(function (error) {
						alert(textData.layer4.mail_alert.success);
						window.location.reload();
					});
				}).catch((error) => {
					alert(textData.layer4.mail_alert.failure)
				});
			})
			.catch(err => {
			});
		}
	};

	return (
		<div data-aos="fade-up">

			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/About">About US </a></li>
									<li className="breadcrumb-item active" aria-current="page">Careers</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid p-0">
				<div className="inner-banner">
					<img src={textData.layer1.img} className="d-block w-100" alt="banner-image" />
					<div className="inner-banner-text">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
									<h2 class="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1} <span>{textData.layer1.heading.text2}</span><br /> {textData.layer1.heading.text3}<br /> {textData.layer1.heading.text4} <span>{textData.layer1.heading.text5}</span></h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid our-values">
				<div className="container">
					<h2>{textData.layer2.heading.text1} <span>{textData.layer2.heading.text2}</span></h2>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values1}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values2}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values3}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values4}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values5}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values6}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="container-fluid liferay-inner">
				<div className="container">
					<h2>{textData.layer3.heading}</h2>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-">
							<p>{textData.layer3.body.text1}</p>&nbsp;
							<p>{textData.layer3.body.text2}</p>&nbsp;
							<p>{textData.layer3.body.text3}</p>&nbsp;
						</div>

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-">
							{
								textData.layer3.editable_collapsibles.map((item, index) => {
									return(
										<div className="panel panel-default">
											<div className="panel-heading" role="tab" id={ "heading" + index }>
												<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapse' + index ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapse' + index)}
													aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }>{item.job}</a></h4>
											</div>
											<div className={`collapse ${activePanel === 'collapse' + index ? 'show' : ''}`}
												id={"collapse" + index} aria-labelledby={"heading" + index} data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
												<div className="panel-body">
													{/* <p>open</p> */}
													{
														item.details.map((sub_item, sub_index) => {
															if ( sub_item.bullets ) {
																return (
																	<p>
																		<h5>{sub_item.heading}</h5>
																		<ul className="disc" class="collapseul">
																			{
																				sub_item.body.map((body_val, body_idx) => {
																					return (
																						<li>{body_val}</li>
																					)
																				})
																			}
																		</ul>
																		<br/>
																	</p>
																)
															} else {
																return (
																	<p>
																		<h5>{sub_item.heading}</h5>
																		{
																			sub_item.body.map((body_val, body_idx) => {
																				return (
																					<p>{body_val}</p>
																				)
																			})
																		}
																		<br/>
																	</p>
																)
															}
														})
													}
													<div className="apply">
														<a className="btn btn-tertiary" type="button" name={item.job} onClick={handleOpen}>{item.button.text}</a>
													</div>
												</div>
											</div>
										</div>
									)
								})
							}

						</div>
						<ReactModal isOpen={modalButton} style={customStyles} >
							<div><button onClick={handleClose} className='close-btn'>{textData.layer4.closebtn}</button></div>
								<form onSubmit={btnclick} className='contact career-form'> 
									<h2>{textData.layer4.heading}{jobName}</h2>
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6 col-">
											<div className="success1">
												<input type="text" value={formData.firstname} onChange={handleChange} className="form-control" name="Firstname" id="FirstName" required />
												<label for="FirstName" className="form-label">{textData.layer4.form.field1}</label>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6 col-">
											<div className="success1">
												<input type="text" value={formData.lastname} onChange={handleChange} className="form-control" name="Lastname" id="LastName" required />
												<label for="LastName" className="form-label">{textData.layer4.form.field2}</label>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6 col-">
											<div className="success1">
												<input type="email" value={formData.email} onChange={handleChange} className="form-control" name="Email" id="Email" required />
												<label for="Email" className="form-label">{textData.layer4.form.field3}</label>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6 col-">
											<div className="success1">
												<input type="number" className="form-control" value={formData.phone} onChange={handleChange}  name="Phone" id="Phone" title="Only numbers" pattern="[0-9]{10}" required />
												<label for="Phone" className="form-label">{textData.layer4.form.field4}</label>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6 col-">
											<div className="success1">
												<input type="text" value={formData.city} onChange={handleChange}  className="form-control" name="City" id="City" required />
												<label for="city" className="form-label">{textData.layer4.form.field5}</label>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6 col-">
											<div className="success1">
												<select id="Country" name="Country" className="form-control" onChange={handleChange} required>
													<option value="">Select</option>
													{
														countryList.data.map(function(item){
															return(<option value={item.name}>{item.name}</option>)
														})
													}
												</select>
												<label for="Country" className="form-label">{textData.layer4.form.field6}</label>
											</div>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 col-">
											<div className="success1">
												<textarea className="form-control" value={formData.comment} onChange={handleChange} name="Comments" id="Comments" rows="3"></textarea>
												<label for="Comments" className="form-label">{textData.layer4.form.field7}</label>
											</div>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 col-">
											<div className="success1">
												<input type="file" accept="application/pdf" className="form-control" value={formData.attachment} onChange={handleChange} name="attachment" id="attachment" required></input>
												<label for="attachment" className="form-label">{textData.layer4.form.field8}</label>
											</div>
										</div>&nbsp;
										<div className="mb-12 form-check">
											<input type="checkbox"  value={formData.agree1} onClick={handleChange} name="check1" className="form-check-input" id="agree1" required />
											<label className="form-check-label" for="agree1">{textData.layer4.form.field9}</label>
										</div>
										<div className="mb-12 form-check">
											<input type="checkbox"  value={formData.agree2} onClick={handleChange} name="check2" className="form-check-input" id="agree2" />
											<label className="form-check-label" for="agree2">{textData.layer4.form.field10}</label>
										</div>
										<div className="mb-12">
								            <ReCaptcha setIsCaptchaValid={setIsCaptchaValid}/>
							            </div>
										<button type="submit" id="clickButton" className="btn" disabled={true} >{textData.layer4.formbutton}</button>
									</div>
								</form>
						</ReactModal>
					</div>
					<div className="container">
						<h2>{textData.layer5.heading}</h2>
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-">
								<div id="carouselExample" className="carousel slide">
									<div className="carousel-inner">
										{
											textData.layer5.editable_carousel.map((item, index) => {
												if ( index === 0 ) {
													return (
														<div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
															<div className="row">
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																	<div className="card-11">
																		<h5>{item.left.serial_no}</h5>
																		<h6>{item.left.heading}</h6>
																		<p>{item.left.body}</p>&nbsp;
																	</div>
																</div>
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																	<div className="card-11">
																		<h5>{item.right.serial_no}</h5>
																		<h6>{item.right.heading}</h6>
																		<p>{item.right.body}</p>&nbsp;
																	</div>
																</div>
															</div>
														</div>
													)
												} else {
													return (
														<div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
															<div className="row">
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																	<div className="card-11">
																		<h5>{item.left.serial_no}</h5>
																		<h6>{item.left.heading}</h6>
																		<p>{item.left.body}</p>&nbsp;
																	</div>
																</div>
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																	<div className="card-11">
																		<h5>{item.right.serial_no}</h5>
																		<h6>{item.right.heading}</h6>
																		<p>{item.right.body}</p>&nbsp;
																	</div>
																</div>
															</div>
														</div>
													)
												}
											})
										}
										{/* <div className="carousel-item carousel-item-1 active">
											<div className="row">
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<div className="card-11">
														<h5>{textData.layer5.details.data1.serial_no}</h5>
														<h6>{textData.layer5.details.data1.heading}</h6>
														<p>{textData.layer5.details.data1.body}</p>&nbsp;
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<div className="card-11">
														<h5>{textData.layer5.details.data2.serial_no}</h5>
														<h6>{textData.layer5.details.data2.heading}</h6>
														<p>{textData.layer5.details.data2.body}</p>&nbsp;
													</div>
												</div>
											</div>
										</div> */}
										{/* <div className="carousel-item carousel-item-2">
											<div className="row">
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<div className="card-11">
														<h5>{textData.layer5.details.data3.serial_no}</h5>
														<h6>{textData.layer5.details.data3.heading}</h6>
														<p>{textData.layer5.details.data3.body}</p>&nbsp;
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<div className="card-11">
														<h5>{textData.layer5.details.data4.serial_no}</h5>
														<h6>{textData.layer5.details.data4.heading}</h6>
														<p>{textData.layer5.details.data4.body}</p>&nbsp;
													</div>
												</div>
											</div>
										</div> */}
										{/* <div className="carousel-item carousel-item-2">
											<div className="row">
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<div className="card-11">
														<h5>{textData.layer5.details.data5.serial_no}</h5>
														<h6>{textData.layer5.details.data5.heading}</h6>
														<p>{textData.layer5.details.data5.body}</p>&nbsp;
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-"></div>
											</div>
										</div> */}
									</div>
									<div className="control-btn-12">
										<button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
											<i className="fa-solid fa-arrow-right"></i>
											<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
											<span className="visually-hidden">{textData.layer5.button_next}</span>
										</button>
										<button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
											<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
											<i className="fa-solid fa-arrow-left"></i>
											<span className="visually-hidden">{textData.layer5.button_previous}</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<h2>{textData.layer6.heading} </h2>
						<p>{textData.layer6.body.text1}</p>&nbsp;
						<p>{textData.layer6.body.text2}</p>&nbsp;
						<p>{textData.layer6.body.text3}</p>&nbsp;
					</div>
					<div className="container">
						<h2>{textData.layer7.heading.text1}<br />{textData.layer7.heading.text2}</h2>
						<p>{textData.layer7.body}</p>&nbsp;
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-">
								<div id="carouselExampleTalent" className="carousel slide">
									<div className="carousel-inner">
									{
											textData.layer7.editable_carousel.map((item, index) => {
												if ( index === 0 ) {
													return (
														<div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
															<div className="row">
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																	<div className="card-11">
																		<h5>{item.left.serial_no}</h5>
																		<h6>{item.left.heading}</h6>
																		<p>{item.left.body}</p>&nbsp;
																	</div>
																</div>
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																	<div className="card-11">
																		<h5>{item.right.serial_no}</h5>
																		<h6>{item.right.heading}</h6>
																		<p>{item.right.body}</p>&nbsp;
																	</div>
																</div>
															</div>
														</div>
													)
												} else {
													return (
														<div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
															<div className="row">
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																	<div className="card-11">
																		<h5>{item.left.serial_no}</h5>
																		<h6>{item.left.heading}</h6>
																		<p>{item.left.body}</p>&nbsp;
																	</div>
																</div>
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
																	<div className="card-11">
																		<h5>{item.right.serial_no}</h5>
																		<h6>{item.right.heading}</h6>
																		<p>{item.right.body}</p>&nbsp;
																	</div>
																</div>
															</div>
														</div>
													)
												}
											})
										}
										{/* <div className="carousel-item carousel-item-1 active">
											<div className="row">
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<div className="card-11">
														<h5>{textData.layer7.details.data1.serial_no}</h5>
														<h6>{textData.layer7.details.data1.heading}</h6>
														<p>{textData.layer7.details.data1.body}</p>&nbsp;
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<div className="card-11">
														<h5>{textData.layer7.details.data2.serial_no}</h5>
														<h6>{textData.layer7.details.data2.heading}</h6>
														<p>{textData.layer7.details.data2.body}</p>&nbsp;
													</div>
												</div>
											</div>
										</div>
										<div className="carousel-item carousel-item-2">
											<div className="row">
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<div className="card-11">
														<h5>{textData.layer7.details.data3.serial_no}</h5>
														<h6>{textData.layer7.details.data3.heading}</h6>
														<p>{textData.layer7.details.data3.body}</p>&nbsp;
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12 col-xs-">
													<div className="card-11">
														<h5>{textData.layer7.details.data4.serial_no}</h5>
														<h6>{textData.layer7.details.data4.heading}</h6>
														<p>{textData.layer7.details.data4.body}</p>&nbsp;
													</div>
												</div>
											</div>
										</div> */}
									</div>
									<div className="control-btn-12">
										<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleTalent" data-bs-slide="next">
											<i className="fa-solid fa-arrow-right"></i>
											<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
											<span className="visually-hidden">{textData.layer7.button_next}</span>
										</button>
										<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleTalent" data-bs-slide="prev">
											<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
											<i className="fa-solid fa-arrow-left"></i>
											<span className="visually-hidden">{textData.layer7.button_next}</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>&nbsp;

				<div className="container-fluid loc-img">
					<div className="container">
						<h2>{textData.layer8.heading}</h2>
						<div className="row">
						{
							textData.layer8.editableList_locations.map(function(item){
								return(<div class="col-lg-3 col-md-3 col-sm-12 col-">
											<div class="location">
												<img src={item.img} alt="gif" class="img-fluid" />
												<p>{item.city}</p>
											</div>
										</div>)
							  })
						}
							{/* <div className="col-lg-3 col-md-3 col-sm-12 col-">
								<div className="location">
									<img src={textData.layer8.detail.location1.img} alt="gif" className="img-fluid" />
									<p>{textData.layer8.detail.location1.city}</p>
								</div>
							</div>
							<div className="col-lg-3 col-md-3 col-sm-12 col-">
								<div className="location">
									<img src={textData.layer8.detail.location2.img} alt="gif" className="img-fluid" />
									<p>{textData.layer8.detail.location2.city}</p>
								</div>
							</div>
							<div className="col-lg-3 col-md-3 col-sm-12 col-">
								<div className="location">
									<img src={textData.layer8.detail.location3.img} alt="gif" className="img-fluid" />
									<p>{textData.layer8.detail.location3.city}</p>
								</div>

							</div>
							<div className="col-lg-3 col-md-3 col-sm-12 col-">
								<div className="location">
									<img src={textData.layer8.detail.location4.img} alt="gif" className="img-fluid" />
									<p>{textData.layer8.detail.location4.city}</p>
								</div>
							</div> */}
						</div>
					</div>
				</div>

				<div className="container-fluid alliance">
					<div className="container">
						<div className="row">
							<div className="col-lg-7 col-md-6 col-sm-12 col-">
								<div className="heading">
									<h2><span>{textData.layer9.heading.text1}</span>{textData.layer9.heading.text2}<span>{textData.layer9.heading.text3}</span></h2>
								</div>
								<div className="more-2">
									<a href={textData.layer9.button.url}>{textData.layer9.button.text}<i className="fa-solid fa-arrow-right"></i></a>
								</div>
							</div>
							<div className="col-lg-5 col-md-6 col-sm-12 col-">
								<div className="gif">
									<img src={textData.layer9.button.img} alt="gif" className="img-fluid" />
								</div>
							</div>
						</div>
					</div>
					<div className="TA-bg">
						<div className="container">
							<div className="row">
								<h2>{textData.layer10.heading}</h2>
								<div className="col-lg-12 col-md-12 col-sm-12 col-">
									<img src={textData.layer10.img} alt="logos" className="img-fluid" />

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

	)
}

export default Careers;