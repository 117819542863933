import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

// const manu_banner = './content/public/images/Manufacturing_Banner.webp';
// const man_story = "./content/public/images/Manuifacturing_Story.webp";
// const driving = './content/public/images/Driving.webp';
// const solution = './content/public/images/Resilient_solutions.webp';
// const ind_logo = './content/public/images/Industries-logo.webp';
// const sucess_story1 = './content/public/images/Success_Stories-1.webp';
// const sucess_story2 = './content/public/images/Success_Stories-2.webp';
// const sucess_story3 = './content/public/images/Success_Stories-3.webp';

const textDataUrl = page_json.page_jsons.CharitableOrganization;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function CharitableOrganization() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	const [activePanel, setActivePanel] = useState(null);
	const togglePanel = (panelId) => {
		setActivePanel(activePanel === panelId ? null : panelId);
	};
	var left_index = 0;

	return (

		<div data-aos="fade-up">
			<div class="container-fluid m-0 p-0 brd-crmb">
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Home</a></li>
									<li class="breadcrumb-item"><a href="/Industries/Healthcare">Healthcare</a></li>
									<li class="breadcrumb-item active" aria-current="page">Chartiable Organization</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid casestudy">
				<div class="container">
					<h2>{textData.layer1.heading1.text1} <span>{textData.layer1.heading1.text2} </span></h2><br />
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-">
							<div class="casestudy1">
								<p><b>{textData.layer1.body1.text1} </b></p>
								<p>{textData.layer1.body1.text2} </p>
							</div>
							<div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
								<div class="row">
									<div class="col-lg-6 col-md-6 col-sm-6 col-xs-">
									{
										textData.layer1.collapsibles_left.map((item, index) => {
											left_index = index
											return(
												<div class="panel panel-default">
													<div class="panel-heading" role="tab" id={ "heading" + index }>
														<h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
															aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }> {item.heading} </a></h4>
													</div>
													<div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
														data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
														<div class="panel-body">
															<p>{item.body}</p>
														</div>
													</div>
												</div>
											)
										})
									}
									</div>
									<div class="col-lg-6 col-md-6 col-sm-6 col-xs-">
									{
										textData.layer1.collapsibles_right.map((item, index) => {
											index = index + left_index + 1
											return(
												<div class="panel panel-default">
													<div class="panel-heading" role="tab" id={ "heading" + index }>
														<h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
															aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }> {item.heading} </a></h4>
													</div>
													<div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
														data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
														<div class="panel-body">
															<p>{item.body}</p>
														</div>
													</div>
												</div>
											)
										})
									}
									</div>
								</div>&nbsp;
								<p>{textData.layer1.body2}</p>
							</div>&nbsp;
							<h5><span>{textData.layer1.heading2}</span></h5>
							<p>{textData.layer1.body3.text1}</p> &nbsp;
							<p>{textData.layer1.body3.text2}</p>
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid chartiable">
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-">
							<img src={textData.layer2.data.detail1.img} alt="gif" class="img-fluid" />
							<p>{textData.layer2.data.detail1.body}</p>
							<img src={textData.layer2.data.detail2.img} alt="gif" class="img-fluid" />
							<p>{textData.layer2.data.detail2.body}</p> &nbsp;
							<p>{textData.layer2.body1}</p> &nbsp;
							<div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
								<div class="row">
									<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									{
										textData.layer2.collapsibles_left.map((item, index) => {
											left_index = index
											return(
												<div class="panel panel-default">
													<div class="panel-heading" role="tab" id={ "heading" + index }>
														<h4 className="panel-title"><a className={`collapsed ${activePanel === "collapseunit" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapseunit" + index)}
															aria-expanded={activePanel === "collapseunit" + index ? 'true' : 'false'} aria-controls={ "collapseunit" + index }> {item.heading} </a></h4>
													</div>
													<div className={`collapse ${activePanel === "collapseunit" + index ? 'show' : ''}`} id={"collapseunit" + index} aria-labelledby={"heading" + index}
														data-parent="#accordion"> <div id={"collapseunit" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
														<div class="panel-body">
															<p>{item.body}</p>
														</div>
													</div>
												</div>
											)
										})
									}
									</div>
								</div>&nbsp;
								<p>{textData.layer2.body2}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-">
							<div className="heading">
								<h2><span>{textData.layer3.heading.text1}</span>{textData.layer3.heading.text2}<span>{textData.layer3.heading.text3}</span></h2>
							</div>
							<div className="more-2">
								<a href={textData.layer3.button.url}>{textData.layer3.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-">
							<div className="gif">
								<img src={textData.layer3.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h2>{textData.layer4.heading}</h2>
							<div className="col-lg-12 col-md-12 col-sm-12 col-">
								<img src={textData.layer4.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default CharitableOrganization;

