
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'

const textDataUrl = page_json.page_jsons.Stack;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Stack() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/Technologies/Salesforce">Technologies </a></li>
									<li className="breadcrumb-item active" aria-current="page">Full Stack</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid p-0">
				<div className="inner-banner">
					<img src={textData.layer1.img} className="d-block w-100" alt="banner-image" />
					<div className="inner-banner-text">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
									<h2 className="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1} <span>{textData.layer1.heading.text2}</span> {textData.layer1.heading.text3}</h2>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid manufacture">
				<div className="container">
					<h2>{textData.layer2.heading.text1}<br />{textData.layer2.heading.text2}</h2>
					<div className="row">
						<div className="col-lg-4 col-md-12 col-sm-12 col-">
							<div className="data-text">
								<p>{textData.layer2.body}</p>
							</div>
						</div>
						<div className="col-lg-8 col-md-12 col-sm-12 col-">
							<div className="manufacture-img">
								<img src={textData.layer2.img} className="img-fluid" alt="industry" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid Resilient">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6 col-">
							<img src={textData.layer3.img} className="img-fluid" alt="Resilient_solutions" />
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-">
							<div className="card-1">
								<h2>{textData.layer3.heading} </h2>
								<p>{textData.layer3.body.text1}</p>&nbsp;
								<p>{textData.layer3.body.text2}</p>
							</div>
						</div>
					</div>
					<div className="row services-inner">
						<h2>{textData.layer4.heading}</h2>
						<div id="carouselExample" className="carousel slide">
							<div className="carousel-inner">
							{
                                    textData.layer4.editable_details.map((item, index) => {
                                        if (index === 0 ) {
                                            return( 
                                                <div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                   <div className="row">
														<div className="col-lg-4 col-md-4 col-sm-4 col-">
															<img src={item.img} alt="Certificate" className="img-fluid" />
														</div>
														<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
															<div className="card-6">
																<h5>{item.serial_no}</h5>
																<h6>{item.title}</h6>
																<p>{item.body.text1}</p>&nbsp;
																<p>{item.body.text2}</p>&nbsp;
															</div>
														</div>
													</div>
                                                </div>)
                                            } else {
                                                return( 
                                                <div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                    <div className="row">
														<div className="col-lg-4 col-md-4 col-sm-4 col-">
															<img src={item.img} alt="Certificate" className="img-fluid" />
														</div>
														<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
															<div className="card-6">
																<h5>{item.serial_no}</h5>
																<h6>{item.title}</h6>
																<p>{item.body.text1}</p>&nbsp;
																<p>{item.body.text2}</p>&nbsp;
															</div>
														</div>
													</div>
                                                </div>
                                            )}
                                        })
                                        
                                }

								{/* <div className="carousel-item carousel-item-1 active">
									<div className="row">
										<div className="col-lg-4 col-md-4 col-sm-4 col-">
											<img src={textData.layer4.data.services1.img} alt="Certificate" className="img-fluid" />
										</div>
										<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
											<div className="card-6">
												<h5>{textData.layer4.data.services1.serial_no}</h5>
												<h6>{textData.layer4.data.services1.title}</h6>
												<p>{textData.layer4.data.services1.body.text1}</p>&nbsp;
												<p>{textData.layer4.data.services1.body.text2}</p>&nbsp;
											</div>
										</div>
									</div>
								</div> */}
								{/* <div className="carousel-item carousel-item-2">
									<div className="row">
										<div className="col-lg-4 col-md-4 col-sm-4 col-">
											<img src={textData.layer4.data.services2.img} alt="Certificate" className="img-fluid" />
										</div>
										<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
											<div className="card-6">
												<h5>{textData.layer4.data.services2.serial_no}</h5>
												<h6>{textData.layer4.data.services2.title}</h6>
												<p>{textData.layer4.data.services2.body.text1}</p>&nbsp;
											</div>
										</div>
									</div>
								</div> */}
								{/* <div className="carousel-item carousel-item-2">
									<div className="row">
										<div className="col-lg-4 col-md-4 col-sm-4 col-">
											<img src={textData.layer4.data.services3.img} alt="Certificate" className="img-fluid" />
										</div>
										<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
											<div className="card-6">
												<h5>{textData.layer4.data.services3.serial_no}</h5>
												<h6>{textData.layer4.data.services3.title}</h6>
												<p>{textData.layer4.data.services3.body.text1}</p>&nbsp;
											</div>
										</div>
									</div>
								</div> */}
								{/* <div className="carousel-item carousel-item-2">
									<div className="row">
										<div className="col-lg-4 col-md-4 col-sm-4 col-">
											<img src={textData.layer4.data.services4.img} alt="Certificate" className="img-fluid" />
										</div>
										<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
											<div className="card-6">
												<h5>{textData.layer4.data.services4.serial_no}</h5>
												<h6>{textData.layer4.data.services4.title}</h6>
												<p>{textData.layer4.data.services4.body.text1}</p>&nbsp;
											</div>
										</div>
									</div>
								</div> */}
								{/* <div className="carousel-item carousel-item-2">
									<div className="row">
										<div className="col-lg-4 col-md-4 col-sm-4 col-">
											<img src={textData.layer4.data.services5.img} alt="Certificate" className="img-fluid" />
										</div>
										<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
											<div className="card-6">
												<h5>{textData.layer4.data.services5.serial_no}</h5>
												<h6>{textData.layer4.data.services5.title}</h6>
												<p>{textData.layer4.data.services5.body.text1}</p>&nbsp;
											</div>
										</div>
									</div>
								</div> */}
								{/* <div className="carousel-item carousel-item-2">
									<div className="row">
										<div className="col-lg-4 col-md-4 col-sm-4 col-">
											<img src={textData.layer4.data.services6.img} alt="Certificate" className="img-fluid" />
										</div>
										<div className="col-lg-8 col-md-8 col-sm-8 col-xs-">
											<div className="card-6">
												<h5>{textData.layer4.data.services6.serial_no}</h5>
												<h6>{textData.layer4.data.services6.title}</h6>
												<p>{textData.layer4.data.services6.body.text1}</p>&nbsp;
											</div>
										</div>
									</div>
								</div> */}
							</div>
							<div className="control-btn-8">
								<button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
									<i className="fa-solid fa-arrow-right"></i>
									<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
									<span className="visually-hidden">{textData.layer4.button_next}</span>
								</button>
								<button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
									<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
									<i className="fa-solid fa-arrow-left"></i>
									<span className="visually-hidden">{textData.layer4.button_previous}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid trust">
				<div className="container trusted-image">
					<div className="row">
						<h2>{textData.layer5.heading}</h2>
						<div className="col-lg-12 col-md-12 col-sm-12 col-">
							<img src={textData.layer5.img} alt="logos" className="img-fluid" />

						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-">
							<div className="heading">
								<h2><span>{textData.layer6.heading.text1}</span>{textData.layer6.heading.text2}<span>{textData.layer6.heading.text3}</span></h2>
							</div>
							<div className="more-2">
								<a href={textData.layer6.button.url}>{textData.layer6.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-">
							<div className="gif">
								<img src={textData.layer6.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h2>{textData.layer7.heading}</h2>
							<div className="col-lg-12 col-md-12 col-sm-12 col-">
								<img src={textData.layer7.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Stack;