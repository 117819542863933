
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import axios from '../api/axios';
import page_json from './config/config.json'

// const banner1 = 'https://engineersmind-websitecontent.s3.amazonaws.com/EM_Home_Page_Banner-1.webp';
// const banner2 = 'https://engineersmind-websitecontent.s3.amazonaws.com/AWS-banner.webp';
// const banner3 = 'https://engineersmind-websitecontent.s3.amazonaws.com/Microsoft+Azure.webp';
// const layer1 = 'https://engineersmind-websitecontent.s3.amazonaws.com/1-Layer-4.webp';
// const layer2 = 'https://engineersmind-websitecontent.s3.amazonaws.com/2-Layer-4.webp';
// const layer3 = 'https://engineersmind-websitecontent.s3.amazonaws.com/3-Layer-4.webp';
// const e = 'https://engineersmind-websitecontent.s3.amazonaws.com/e.webp';
// const logo = 'https://engineersmind-websitecontent.s3.amazonaws.com/Logos.webp';
// const banner_logo = 'https://engineersmind-websitecontent.s3.amazonaws.com/Banner-Logos.webp';
// const trusted_logo = 'https://engineersmind-websitecontent.s3.amazonaws.com/Trusted_by_Logos.webp';
// const financial = 'https://engineersmind-websitecontent.s3.amazonaws.com/Financial.webp';
// const healthcare = 'https://engineersmind-websitecontent.s3.amazonaws.com/Healthcare.webp';
// const manufacture = 'https://engineersmind-websitecontent.s3.amazonaws.com/Manufacturing.webp';
// const clientlogos = 'https://engineersmind-websitecontent.s3.amazonaws.com/client_Logos.webp';
// const clienticons = 'https://engineersmind-websitecontent.s3.amazonaws.com/Clientele_Icons.webp';
// const gif = 'https://engineersmind-websitecontent.s3.amazonaws.com/Em_Animation-1.gif';

const textDataUrl = page_json.page_jsons.Body;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Body() {
    const [data, setData] = useState([]);
    useEffect(() => {
        AOS.init();
        axios.get('/pages')
            .then(response => setData(response.data))
            .catch(error => console.log(error));
    }, []);
    // if(Array.isArray(data.data)){
    //     let Home = data.data.filter(element => element.page_name === "Home");
    //     console.log('home data' + JSON.stringify(Home[0].page_name));
    // }else{
    //     console.error("data is not in array format");
    // }
    console.log('home data 1' + JSON.stringify(data));

    return (
        <div data-aos="fade-up">
            <div class="container-fluid p-0">
                <div id="carouselExampleAutoplaying" class="carousel slide">
                    <div class="carousel-inner">
                        {
                            textData.editable_carousel_banner.map((item, index) => {
                                if (index === 0 ) {
                                    return( 
                                        <div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                    <img src={item.img} class="d-block w-100" alt="banner-image" />
                                                    <div class="banner-text">
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                                    <h2 class="animate__animated animate__fadeInLeft"><span>{item.heading.text1}</span>{item.heading.text2}<br /> <span>{item.heading.text3}</span> {item.heading.text4}<br />{item.heading.text5}</h2>
                                                                    <a href={item.button.url} class="animate__animated animate__fadeInLeft">{item.button.text} <i class="fa-solid fa-arrow-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="banner-logos">
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                                    <img src={item.banner.img} class="img-dluid" alt="banner-logo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    } else {
                                        return( 
                                        <div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                    <img src={item.img} class="d-block w-100" alt="banner-image" />
                                                    <div class="banner-text">
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                                    <h2 class="animate__animated animate__fadeInLeft"><span>{item.heading.text1}</span>{item.heading.text2}<br /> <span>{item.heading.text3}</span> {item.heading.text4}<br />{item.heading.text5}</h2>
                                                                    <a href={item.button.url} class="animate__animated animate__fadeInLeft">{item.button.text} <i class="fa-solid fa-arrow-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="banner-logos">
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                                    <img src={item.banner.img} class="img-dluid" alt="banner-logo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            })
                                
                        }
                        {/* <div class="carousel-item carousel-item-1 active">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                    <img src={textData.editable_carousel_banner.img} class="d-block w-100" alt="banner-image" />
                                    <div class="banner-text">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <h2 class="animate__animated animate__fadeInLeft"><span>{textData.editable_carousel_banner.heading.text1}</span>{textData.editable_carousel_banner.heading.text2}<br /> <span>{textData.editable_carousel_banner.heading.text3}</span> {textData.editable_carousel_banner.heading.text4}<br />{textData.editable_carousel_banner.heading.text5}</h2>
                                                    <a href={textData.editable_carousel_banner.button.url} class="animate__animated animate__fadeInLeft">{textData.editable_carousel_banner.button.text} <i class="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="banner-logos">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <img src={textData.editable_carousel_banner.banner.img} class="img-dluid" alt="banner-logo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div class="carousel-item carousel-item-2">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                    <img src={banner2} class="d-block w-100" alt="banner-image" />
                                    <div class="banner-text">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <h2 class="animate__animated animate__fadeInLeft"><span>Empower</span> your<br /> organization with<br /> Data and AI</h2>
                                                    <a href="/Services/AI&Data" class="animate__animated animate__fadeInLeft">Explore <i class="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="banner-logos">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <img src={banner_logo} class="img-dluid" alt="banner-logo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item carousel-item-2">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                    <img src={banner3} class="d-block w-100" alt="banner-image" />
                                    <div class="banner-text">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <h2 class="animate__animated animate__fadeInLeft">EM <span>Tech Talent Solutions</span><br />
                                                        Your gateway to innovation,<br /> excellence & growth</h2>
                                                    <a href="/Services/TechnologyTalent" class="animate__animated animate__fadeInLeft">Explore <i class="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="banner-logos">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-">
                                                    <img src={banner_logo} class="img-dluid" alt="banner-logo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div class="control-btn-2">
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <i class="fa-solid fa-arrow-right"></i>
                            <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                            <i class="fa-solid fa-arrow-left"></i>
                            <span class="visually-hidden">Previous</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="container-fluid layer-2">
                <div class="container">
                    <h2>{textData.layer2.heading}</h2>
                    <div class="card-header">
                        <ul class="nav nav-tabs justify-content-center" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#financial" role="tab">{textData.layer2.subheadings.subheading1}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#healthcare" role="tab">{textData.layer2.subheadings.subheading2}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#manufacturing" role="tab">{textData.layer2.subheadings.subheading3}</a>
                            </li>
                        </ul>
                    </div>&nbsp;
                    <div class="card-body">
                        <div class="tab-content text-center">
                            <div class="tab-pane active" id="financial" role="tabpanel">
                                <div class="indus-txt">
                                    <p>{textData.layer2.details.detail1.body.text1}</p>
                                    <p>{textData.layer2.details.detail1.body.text2}</p>
                                    <p>{textData.layer2.details.detail1.body.text3}</p>
                                    <a href={textData.layer2.details.detail1.button.link}>{textData.layer2.details.detail1.button.text} <i class="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div class="tab-pane" id="healthcare" role="tabpanel">
                                <div class="indus-txt">
                                    <p>{textData.layer2.details.detail2.body.text1}</p>
                                    <p>{textData.layer2.details.detail2.body.text2}</p>
                                    <a href={textData.layer2.details.detail2.button.link}>{textData.layer2.details.detail2.button.text} <i class="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div class="tab-pane" id="manufacturing" role="tabpanel">
                                <div class="indus-txt">
                                    <p>{textData.layer2.details.detail3.body.text1}</p>
                                    <p>{textData.layer2.details.detail3.body.text2}</p>
                                    <p>{textData.layer2.details.detail3.body.text3}</p>
                                    <a href={textData.layer2.details.detail3.button.link}>{textData.layer2.details.detail3.button.text}<i class="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fliud testimonial">
                <h2>{textData.layer3.heading.text1}<br />{textData.layer3.heading.text2}</h2>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-">
                            <img src={textData.layer3.img} alt="logos" class="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid testi-home">
                <div class="container">
                    <h2>{textData.layer4.heading}</h2>
                    <div class="row">
                        <div class="col-lg-12 col-md 12 col-sm-12 col-xs-">
                            <div id="carouselExampleCaptions" class="carousel slide">
                                <div class="carousel-inner">
                                    {
                                        textData.layer4.editable_carousel.map((item, index) => {
                                            if (index === 0 ) {
                                                return( 
                                                    <div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                        <div class="row">
                                                            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-"></div>
                                                            <div class="col-lg-10 col-md-10 col-sm-12 col-xs-">
                                                                <p><i class="fa-solid fa-quote-left"></i>{item.testimony}<i class="fa-solid fa-quote-right"></i></p>&nbsp;
                                                                <p><b>{item.name}</b><br /><i>{item.post}</i></p>
                                                                <div class="buttoncss">
                                                                    <div class="control-btn-3">
                                                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                                            <i class="fa-solid fa-arrow-right"></i>
                                                                            <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                                            <span class="visually-hidden">Next</span>
                                                                        </button>
                                                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                                            <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                                            <i class="fa-solid fa-arrow-left"></i>
                                                                            <span class="visually-hidden">Previous</span>
                                                                        </button>
                                                                    </div>
                                                                    <p class="morecss">
                                                                        <a href={item.button.link}>{item.button.text} <i class="fa-solid fa-arrow-right"></i></a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                } else {
                                                    return( 
                                                    <div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                        <div class="row">
                                                            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-"></div>
                                                            <div class="col-lg-10 col-md-10 col-sm-12 col-xs-">
                                                                <p><i class="fa-solid fa-quote-left"></i>{item.testimony}<i class="fa-solid fa-quote-right"></i></p>&nbsp;
                                                                <p><b>{item.name}</b><br /><i>{item.post}</i></p>
                                                                <div class="buttoncss">
                                                                    <div class="control-btn-3">
                                                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                                            <i class="fa-solid fa-arrow-right"></i>
                                                                            <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                                            <span class="visually-hidden">Next</span>
                                                                        </button>
                                                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                                            <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                                            <i class="fa-solid fa-arrow-left"></i>
                                                                            <span class="visually-hidden">Previous</span>
                                                                        </button>
                                                                    </div>
                                                                    <p class="morecss">
                                                                        <a href={item.button.link}>{item.button.text} <i class="fa-solid fa-arrow-right"></i></a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        })
                    
                                    }


                                    {/* <div class="carousel-item carousel-item-1 active">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-"></div>
                                            <div class="col-lg-10 col-md-10 col-sm-12 col-xs-">
                                                <p><i class="fa-solid fa-quote-left"></i>The Engineersmind team consistently delivers top-notch solutions tailored to our needs. Their expertise, reliability, and customer-centric approach make them an invaluable asset for driving business growth and innovation. I wholeheartedly recommend Engineersmind's services to anyone in search of exceptional IT software development in the US financial services sector...<i class="fa-solid fa-quote-right"></i></p>&nbsp;
                                                <p><b>Chase Hyland</b><br /><i>COO (TRCM Fund)</i></p>
                                                <div class="buttoncss">
                                                    <div class="control-btn-3">
                                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                            <i class="fa-solid fa-arrow-right"></i>
                                                            <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Next</span>
                                                        </button>
                                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                            <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                            <i class="fa-solid fa-arrow-left"></i>
                                                            <span class="visually-hidden">Previous</span>
                                                        </button>
                                                    </div>
                                                    <p class="morecss">
                                                        <a href="">test <i class="fa-solid fa-arrow-right"></i></a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div class="carousel-item carousel-item-2">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-"></div>
                                            <div class="col-lg-10 col-md-10 col-sm-12 col-xs-">
                                                <p><i class="fa-solid fa-quote-left"></i>Engineersmind exhibited a profound understanding of the intricate challenges within the healthcare sector. Engineersmind's software development and DevSecOps services have been outstanding...<i class="fa-solid fa-quote-right"></i></p>&nbsp;
                                                <p><b>Don Holder</b><br /><i>Technology Director (US Healthcare Services)</i></p>
                                                <div class="buttoncss">
                                                    <div class="control-btn-3">
                                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                            <i class="fa-solid fa-arrow-right"></i>
                                                            <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Next</span>
                                                        </button>
                                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                            <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                            <i class="fa-solid fa-arrow-left"></i>
                                                            <span class="visually-hidden">Previous</span>
                                                        </button>
                                                    </div>
                                                    <p class="morecss">
                                                        <a href="/Resources/Testimonials">More <i class="fa-solid fa-arrow-right"></i></a>
                                                    </p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div class="carousel-item carousel-item-2">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-"></div>
                                            <div class="col-lg-10 col-md-10 col-sm-12 col-xs-">
                                                <p><i class="fa-solid fa-quote-left"></i>From software development to business analysis, documentation, testing, and deployment, Engineersmind has consistently delivered outstanding results throughout our partnership. Their DevOps team has enabled us to smoothly introduce new software solutions, minimizing disruptions and maximizing our ROI...<i class="fa-solid fa-quote-right"></i></p>&nbsp;
                                                <p><b>Kaitlin Malin</b><br /><i>Chief Operating Officer (US Financial Services)</i></p>
                                                <div class="buttoncss">
                                                    <div class="control-btn-3">
                                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                            <i class="fa-solid fa-arrow-right"></i>
                                                            <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Next</span>
                                                        </button>
                                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                            <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                            <i class="fa-solid fa-arrow-left"></i>
                                                            <span class="visually-hidden">Previous</span>
                                                        </button>
                                                    </div>
                                                    <p class="morecss">
                                                        <a href="/Resources/Testimonials">More <i class="fa-solid fa-arrow-right"></i></a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div><br />
                                {/* <div class="buttoncss">
                                <p class="morecss">
                                    {/* <p><div class="success-text-1 morecss"> 
                                        <a href="/Resources/Testimonials">More <i class="fa-solid fa-arrow-right"></i></a>
                                    {/* </div> 
                                    </p>
                                    <span>box</span>
                                    <div class="control-btn-3">
                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <i class="fa-solid fa-arrow-right"></i>
                                            <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                            <i class="fa-solid fa-arrow-left"></i>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid success extra-space">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-">
                            <h2><span>{textData.layer5.heading.text1}</span>{textData.layer5.heading.text2}</h2>
                            <div id="carouselExampleInterval" class="carousel slide">
                                <div class="carousel-inner">
                                {
                                    textData.layer5.editable_carousel.map((item, index) => {
                                        if (index === 0 ) {
                                            return( 
                                                <div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                            <div class="success-text-1">
                                                                <p>{item.text.text1}<br />{item.text.text2}<br />{item.text.text3}</p><br />
                                                                <a href={item.button.link}>{item.button.text}<i class="fa-solid fa-arrow-right"></i></a>
                                                            </div>
                                                            <img src={item.img} class="d-block w-100" alt="banner-image" />
                                                        </div>
                                                    </div>
                                                </div>)
                                            } else {
                                                return( 
                                                <div class={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                   <div class="row">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                            <div class="success-text-1">
                                                                <p>{item.text.text1}<br />{item.text.text2}<br />{item.text.text3}</p><br />
                                                                <a href={item.button.link}>{item.button.text}<i class="fa-solid fa-arrow-right"></i></a>
                                                            </div>
                                                            <img src={item.img} class="d-block w-100" alt="banner-image" />
                                                        </div>
                                                    </div>
                                                </div>
                                        )}
                                    })
                                        
                                }
                                    {/* <div class="carousel-item carousel-item-1 active">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div class="success-text-1">
                                                    <p>Accelerated growth powered<br />by Data Engineering,<br /> AI and Automation</p><br />
                                                    <a href="/Services/AI&Data">Explore <i class="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                                <img src={layer3} class="d-block w-100" alt="banner-image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item carousel-item-2">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div class="success-text-1">
                                                    <p>Driving operational excellence<br /> with DevOps and Cloud</p><br />
                                                    <a href="/Technologies/DevOps">Explore <i class="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                                <img src={layer2} class="d-block w-100" alt="banner-image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item carousel-item-2">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-">
                                                <div class="success-text-1">
                                                    <p>Build a scalable, agile and<br />reliable organization with<br />our Managed IT Services<br /></p><br />
                                                    <a href="/Services/ManagedITServices">Explore <i class="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                                <img src={layer1} class="d-block w-100" alt="banner-image" />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div class="control-btn-4">
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                                        <i class="fa-solid fa-arrow-right"></i>
                                        <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                        <i class="fa-solid fa-arrow-left"></i>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid services-home">
                <div class="container">
                    <h2><span>{textData.layer6.heading}</span></h2>
                    <p>{textData.layer6.body}</p>
                    {/* <div class="more-1">
                        <a href="/Services/ManagedITServices">Explore <i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                    <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-12 col-"></div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-">
                            <div class="module-border-wrap">
                                <div class="module">
                                    <a href={textData.layer6.details.detail1.link} id="pdng">{textData.layer6.details.detail1.text}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-">
                            <div class="module-border-wrap">
                                <div class="module">
                                    <a href={textData.layer6.details.detail2.link} id="pdng">{textData.layer6.details.detail2.text}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-">
                            <div class="module-border-wrap">
                                <div class="module">
                                    <a href={textData.layer6.details.detail3.link} id="pdng">{textData.layer6.details.detail3.text}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-">
                            <div class="module-border-wrap">
                                <div class="module">
                                    <a href={textData.layer6.details.detail4.link} id="pdng">{textData.layer6.details.detail4.text}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-"></div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-"></div>
                        <div class="col-lg-3 col-md-3 col-sm-12 col-">
                            <div class="module-border-wrap">
                                <div class="module">
                                    <a href={textData.layer6.details.detail5.link} id="pdng">{textData.layer6.details.detail5.text}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 col-">
                            <div class="module-border-wrap">
                                <div class="module">
                                    <a href={textData.layer6.details.detail6.link} id="pdng">{textData.layer6.details.detail6.text}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-">
                            <div class="module-border-wrap">
                                <div class="module">
                                    <a href={textData.layer6.details.detail7.link} id="pdng">{textData.layer6.details.detail7.text}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-"></div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12 col-">
                            <div class="value-text">
                                <h2><span>{textData.layer7.heading}</span></h2>
                                <h3>{textData.layer7.heading2.text1}<br />{textData.layer7.heading2.text2}</h3>&nbsp;
                                <p>{textData.layer7.heading3.text1}<br />{textData.layer7.heading3.text2}</p><br />
                                <a href={textData.layer7.button.link}>{textData.layer7.button.text} <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-12 col-">
                            <div class="value-img">
                                <img src={textData.layer7.img} class="img-fluid" alt="intro-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid alliance">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-">
                            <div class="heading">
                            <h2><span>{textData.layer8.heading.text1}</span>{textData.layer8.heading.text2}<span>{textData.layer8.heading.text3}</span></h2>
                            </div>
                            <div class="more-2">
                            <a href={textData.layer8.button.url}>{textData.layer8.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-">
                            <div class="gif">
                            <img src={textData.layer8.button.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="TA-bg">
                    <div class="container">
                        <div class="row">
                            <h2>{textData.layer9.heading}</h2>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-">
                            <img src={textData.layer9.img} alt="logos" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Body;