
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'
import AWS from 'aws-sdk';
import { SES } from '@aws-sdk/client-ses';
import Recaptcha from './ReCaptcha';

// const location_bg = './content/public/images/Location_Bg.webp';
// const nj = './content/public/images/New-Jersey.webp';
// const ny = './content/public/images/New_York.webp';
// const noida = './content/public/images/Noida.webp';
// const em_logo = './content/public/images/EM_Logo.webp';
// const bangalore = './content/public/images/Bengaluru.webp';
// const loc1 = 'https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/New-Jersey.webp';
// const loc2 = 'https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/New_York.webp';
// const loc3 = 'https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/Noida.webp';
// const loc4 = 'https://engineersmind-websitecontent.s3.us-east-1.amazonaws.com/Bengaluru.webp';

var textDataUrl = page_json.backend_apis.countries;
var textDataResponse = await fetch(textDataUrl);
const countryList = await textDataResponse.json();

textDataUrl = page_json.page_jsons.Contact;
textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();
function Contact() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	
	const  [formData, setFormData] =  useState({});
	const [isCaptchaValid,setIsCaptchaValid] = useState(false);

	useEffect(()=>{
		handleChange({})
	},[isCaptchaValid])
	
	const  handleChange = (event) => {
		var value = formData
		if (event?.target?.name === "Firstname"){
			value.firstname = event.target.value
		} else if (event?.target?.name === "Information"){
			value.selected = event.target.value
		} else if (event?.target?.name === "Lastname"){
			value.lastname = event.target.value
		} else if (event?.target?.name === "Email"){
			value.email = event.target.value
		} else if (event?.target?.name === "Phone"){
			value.phone = event.target.value
		} else if (event?.target?.name === "City"){
			value.city = event.target.value
		} else if (event?.target?.name === "Country"){
			value.country = event.target.value
		} else if (event?.target?.name === "Comments"){
			value.comment = event.target.value
		} else if (event?.target?.name === "check1"){
			value.check1 = event.currentTarget.checked
		} else if (event?.target?.name === "check2"){
			value.check2 = event.currentTarget.checked
		}
		if (value.firstname !== "" &&  value.selected !== "" &&  value.lastname !== "" &&  value.email !== ""
			&&  value.phone !== "" &&  value.city !== "" &&  value.country !== "" && value.check1 === true && isCaptchaValid) {
			document.getElementById("clickButton").disabled = false;

		} else {
			document.getElementById("clickButton").disabled = true;
		}
		setFormData(value);
	};
	const btnclick = (e) => {
		e.preventDefault();
		var body = "<!DOCTYPE html><html><head><title>Contact page query:</title></head><body><ul><li>name: " + formData.firstname + " " + formData.lastname + "</li><li>email: " + formData.email + "</li><li>Phone: " + formData.phone + "</li><li>City: " + formData.city + "</li><li>Country: " + formData.country + "</li><li>Comment: " + formData.comment + "</li></ul></body></html>";
		const ses = new SES({region: process.env.REACT_APP_AWS_REGION,
			credentials: new AWS.Credentials(process.env.REACT_APP_AWS_ACCESS_KEY_ID, process.env.REACT_APP_AWS_SECRET_ACCESS_KEY) 
		});
		const params = {
			Source: page_json.contact_api.fromEmail,
			Destination: {
			  ToAddresses: [page_json.contact_api.toEmail],
			},
			Message: {
			  Body: {
				Html: {
				  Charset: 'UTF-8',
				  Data: body
				},
			  },
			  Subject: {
				Charset: 'UTF-8',
				Data: "Contact page query from " + formData.firstname + " " + formData.lastname
			  },
			},
		  };
		ses.sendEmail(params)
		.then((response) => {
			alert("Query sent");
			window.location.reload();
		}).catch(function (error) {
			alert("Query failed, try again!");
		})
	};
	return (
		<div data-aos="fade-up">
			<div className="container-fluid m-0 p-0 brd-crmb" id="crumb-2">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item active" aria-current="page">Contact</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid contact">
				<div className="container">
					<h2>{textData.layer1.heading}</h2>
					<p><span>{textData.layer1.body.text1}</span></p>
					<p>{textData.layer1.body.text2}</p>
					<p>{textData.layer1.body.text3}</p>
					<p>{textData.layer1.body.text4}</p>&nbsp;
					<p>{textData.layer1.body.text5}</p>
					<p>{textData.layer1.body.text6}</p>&nbsp;
					&nbsp;
					{/* <form id="myForm" action=" " method="post"> */}
					<form onSubmit={btnclick}> 
						<h2>{textData.layer2.heading}</h2>
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-">
								<div className="success1">
									<select id="Information" name="Information" className="form-control" required onChange={handleChange}>
										<option value="" selected disabled>Select</option>
										<option value="Sales">Sales</option>
										<option value="Consulting">Consulting</option>
										<option value="HR">HR</option>
									</select>
									<label for="Information" className="form-label">{textData.layer2.body.text1}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<input type="text" value={formData.firstname} onChange={handleChange} className="form-control" name="Firstname" id="FirstName" required />
									<label for="FirstName" className="form-label">{textData.layer2.body.text2}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<input type="text" value={formData.lastname} onChange={handleChange} className="form-control" name="Lastname" id="LastName" required />
									<label for="LastName" className="form-label">{textData.layer2.body.text3}</label>
								</div>

							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<input type="email" value={formData.email} onChange={handleChange} className="form-control" name="Email" id="Email" required />
									<label for="Email" className="form-label">{textData.layer2.body.text4}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<input type="number" className="form-control" value={formData.phone} onChange={handleChange}  name="Phone" id="Phone" title="Only numbers" pattern="[0-9]{10}" required />
									<label for="Phone" className="form-label">{textData.layer2.body.text5}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<input type="text" value={formData.city} onChange={handleChange}  className="form-control" name="City" id="City" required />
									<label for="city" className="form-label">{textData.layer2.body.text6}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-">
								<div className="success1">
									<select id="Country" name="Country" className="form-control" onChange={handleChange} required>
										<option value="">{textData.layer2.body.text7}</option>
										{
											countryList.data.map(function(item){
												return(<option value={item.name}>{item.name}</option>)
											  })
										}
									</select>
									<label for="Country" className="form-label">{textData.layer2.body.text8}</label>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-">
								<div className="success1">
									<textarea className="form-control" value={formData.comment} onChange={handleChange} name="Comments" id="Comments" rows="3"></textarea>
									<label for="Comments" className="form-label">{textData.layer2.body.text9} </label>
								</div>
							</div>&nbsp;
							<div className="mb-12 form-check">
								<input type="checkbox"  value={formData.agree1} onClick={handleChange} name="check1" className="form-check-input" id="agree1" required />
								<label className="form-check-label" for="agree1">{textData.layer2.body.text10}</label>
							</div>
							<div className="mb-12 form-check">
								<input type="checkbox"  value={formData.agree2} onClick={handleChange} name="check2" className="form-check-input" id="agree2"/>
								<label className="form-check-label" for="agree2">{textData.layer2.body.text11}</label>
							</div>
							<div className="mb-12">
								<Recaptcha setIsCaptchaValid={setIsCaptchaValid}/>
							</div>
							<button type="submit" id="clickButton" className="btn" disabled={true} >{textData.layer2.body.text12}</button>
						</div>
					</form>

				</div>
			</div>
			<div class="container-fluid loc-img">
				<div class="container">
					<h2>{textData.layer3.heading}</h2>
					<div class="row">
						{
							textData.layer3.editableList_locations.map(function(item){
								return(<div class="col-lg-3 col-md-3 col-sm-12 col-">
											<div class="location">
												<img src={item.img} alt="gif" class="img-fluid" />
												<p>{item.name}</p>
											</div>
										</div>)
							  })
						}
						
						{/* <div class="col-lg-3 col-md-3 col-sm-12 col-">
							<div class="location">
								<img src={loc1} alt="gif" class="img-fluid" />
								<p>New Jersey</p>
							</div>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-">
							<div class="location">
								<img src={loc2} alt="gif" class="img-fluid" />
								<p>New York</p>
							</div>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-">
							<div class="location">
								<img src={loc3} alt="gif" class="img-fluid" />
								<p>Noida</p>
							</div>

						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-">
							<div class="location">
								<img src={loc4} alt="gif" class="img-fluid" />
								<p>Bengaluru</p>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	)


}

export default Contact;



